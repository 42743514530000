import { elementVisibility, isExpired } from "./module";

export function renderEventsCarouselItem(dataArray){
	$(document).ready(function() {
		const carousel = $('.carousel-events');

		dataArray.forEach(event => {
			const response = createEventItem(event);
			carousel.append(response);
		});

		// Initialize Owl Carousel
		carousel.owlCarousel({
			autoplay: true,
			center: true,
			loop: true,
			items: 1,
			margin: 30,
			stagePadding: 0,
			nav: true,
			navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
			responsive:{
				0:{
					items: 1,
					stagePadding: 0
				},
				600:{
					items: 2,
					stagePadding: 50
				},
				1000:{
					items: 3,
					stagePadding: 100
				}
			}
		});

		elementVisibility("#events", 1)
	});
}

function createEventItem(data) {
	const eventItem = document.createElement('div');
	eventItem.className = 'events-item item';
	eventItem.setAttribute('data-id', data.id);

	const blogEntry = document.createElement('div');
	blogEntry.className = 'blog-entry event-entry align-self-stretch';
	const anchor = document.createElement('a');
	anchor.href = data.fileURL;

	anchor.className = 'block-20';
	anchor.style.backgroundImage = `url(${data.imgURL})`;
	const eventDate = document.createElement('div');
	eventDate.className = 'event-date';
	eventDate.innerHTML = `<p class="text-bold">${data.date}</p>`;

	let redirectVal = `<p><a href="${data.redirectURL}" target="_blank">Join Event <i class="ion-ios-arrow-forward ml-2"></i></a></p>`;
	if(data.redirectURL == '0'){
		redirectVal = `<p><a href="${data.fileURL}" target="_blank">Know more <i class="ion-ios-arrow-forward ml-2"></i></a></p>`;
	}

	// let joinEventAncor = "";
	// if(redirectVal !== "0" && isExpired(data.date)){
	// 	joinEventAncor = redirectVal;
	// }

	const textDiv = document.createElement('div');
	textDiv.className = 'text p-4 d-block';
	textDiv.innerHTML = `
		<p class="time-loc">
			<span class="mr-2"><i class="icon-clock-o"></i> ${data.time}</span> 
		</p>
		<h3 class="heading mb-4"><a href="${data.fileURL}">${data.title}</a></h3>
		<p><a href="${data.venueURL}"><small><i class="icon-map-o text-capitalize"></i> ${data.venue}</i></small></a></p>
		<p>${data.description}</p>
        ${redirectVal}
    `;
	blogEntry.appendChild(anchor);
	blogEntry.appendChild(eventDate);
	blogEntry.appendChild(textDiv);
	eventItem.appendChild(blogEntry);
	return eventItem;
}