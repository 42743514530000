const { sendFormObject } = require("./module");

const contactForm = document.getElementById('contact-form');
if(contactForm){
    contactForm.addEventListener('submit', function(e) {
        e.preventDefault();
        const formData = new FormData(this);
        sendFormObject(this, formData, "contact_form");
    });
}

const volunteer_form = document.getElementById('volunter-form');
if(volunteer_form){
    volunteer_form.addEventListener('submit', function(e) {
        e.preventDefault();
        const formData = new FormData(this);
        const isSent = sendFormObject(this, formData, "volunteer_form");
    });
}