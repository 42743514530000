import { convertDateString, elementVisibility } from "./module";

export function renderProjectCarouselItem(dataArray){
	$(document).ready(function() {
		const carousel = $('.carousel-project');
        console.log("ProjectArray", dataArray);

		dataArray.forEach(event => {
			const response = createProjectItem(event);
			carousel.append(response);
		});

		// Initialize Owl Carousel
		carousel.owlCarousel({
			autoplay: true,
			center: true,
			loop: true,
			items: 1,
			margin: 30,
			stagePadding: 0,
			nav: true,
			navText: [`<i class='bx bx-chevron-left' ></i>`, `<i class='bx bx-chevron-right' ></i>`],
			responsive:{
				0:{
					items: 1,
					stagePadding: 0
				},
				600:{
					items: 2,
					stagePadding: 50
				},
				1000:{
					items: 3,
					stagePadding: 100
				}
			}
		});

		elementVisibility('#projects', 1);
	});
}

window.createProjectItem = function(data){
	let currentGoalPercentage;
	// Extract the numeric values
	const numericAchieve = extractNumber(data.achieve);
	const numericGoal = extractNumber(data.goal);
	const outputDate = convertDateString(data.date);
	const donationTime = "Last donation on " + outputDate;
	
	if (!isNaN(numericAchieve) && !isNaN(numericGoal)) {
		currentGoalPercentage = (numericAchieve / numericGoal) * 100 + '%';
		//console.log(`Current project completion percentage: ${currentGoalPercentage}`);
	} else{
		currentGoalPercentage = "0%"
	}

	const item = document.createElement('div');
	item.className = 'item mb-5';

	const causeEntry = document.createElement('div');
	causeEntry.className = 'cause-entry project-entry';

	const anchor = document.createElement('a');
	anchor.className = 'img';
	anchor.style.backgroundImage = `url(${data.imgURL})`;

	let fileURL = '#';
	if(data.redirectURL !== '0'){
		fileURL = data.redirectURL;
	} 
	if(data.fileURL !== '0'){
		fileURL = data.fileURL;
	} 

	const textDiv = document.createElement('div');
	textDiv.className = 'text p-3 p-md-4';
	textDiv.innerHTML = `
		<h4><a href="${fileURL}">${data.title}</a></h4>
		<p>${data.description}</p>
		<span class="donation-time mb-3 d-block">${donationTime}</span>
		<div class="progress custom-progress-success">
			<div class="progress-bar bg-primary" role="progressbar" style="width: ${currentGoalPercentage}" aria-valuenow="${currentGoalPercentage}" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
		<small><span class="fund-raised d-block">${data.achieve} ${data.currency}s raised out of ${data.goal} ${data.currency}s <a href="https://panel.hopehomecalcutta.org/storage/attachments/soccer-ball-drive.pdf" target="_blank"><i class='bx bx-question-mark round-small-icon ml-1'></i></a></span></small>
	`;

	causeEntry.appendChild(anchor);
	causeEntry.appendChild(textDiv);
	item.appendChild(causeEntry);

	return item;
}

// Function to extract the numeric part from a string
window.extractNumber = function(value) {
	// Match the numeric part of the string
	const match = value.match(/\d+/g);
	// Join the matched parts and convert to a number
	return match ? parseFloat(match.join('')) : NaN;
}